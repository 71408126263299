import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for order requests
 */
export class OrderUrl extends BasicUrl {
  /**
   * Get all orders request url
   */
  public static getAllOrders(): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['order']);
  }

  /**
   * Get a single order with given id
   * @param companyId Id of the company
   * @param orderId Id of the order
   */
  public static getSingleOrder(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId]);
  }

  /**
   * Update the status or assignee of an order
   * @param companyId Id of the company
   * @param orderId Id of order
   */
  public static updateOrder(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId]);
  }

  /**
   * Update an existing actor
   * @param actorId Id of the actor
   */
  public static updateActor(actorId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['actor', actorId]);
  }

  public static uploadKycFile(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'kyc-file']);
  }

  /**
   * Update an existing actor address of a check
   * @param companyId Id of the company
   * @param orderId Id of the order
   * @param checkId Id of the check
   * @param actorAddressId Id of actor address
   */
  public static updateActorAddress(
    companyId: string,
    orderId: string,
    checkId: string,
    actorAddressId: string
  ): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'actorAddress',
      actorAddressId,
    ]);
  }

  /**
   * Update an existing decision of a check
   * @param companyId Id of the company
   * @param orderId Id of the order
   */
  public static addDecision(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'decision']);
  }

  /**
   * Provide documents for a check
   */
  public static provideDocuments(companyId: string, orderId: string, checkId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'document',
    ]);
  }

  /**
   * Delete document with given id
   */
  public static deleteDocument(companyId: string, orderId: string, checkId: string, documentId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'document',
      documentId,
    ]);
  }

  /**
   * Get all files of given document
   */
  public static getAllFilesOfDocument(companyId: string, orderId: string, checkId: string, documentId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'document',
      documentId,
      'file',
    ]);
  }

  /**
   * Endpoint to get a specific kyc-file
   */
  public static kycFile(companyId: string, orderId: string, kycFileId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'kyc-file',
      kycFileId,
    ]);
  }

  public static orderComplexity(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'setComplexity',
    ]);
  }
}
