import { Base64 } from 'js-base64';

import { LocalStorageKeys, LocalStorageKeyTypes } from '@models';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

/**
 * Service that handles local storage. Ensures consistent keys and en-/decodes the values
 */
export class LocalStorageService {
  /**
   * Adds key-value pair to local storage
   * @param key Name of key
   * @param value Value of item
   */
  public static add(key: LocalStorageKeys, value: string | number | boolean | object): void {
    // Encode the value and set in localStorage
    switch (true) {
      default:
      case key.toString().endsWith(LocalStorageKeyTypes.BOOLEAN):
      case key.toString().endsWith(LocalStorageKeyTypes.NUMBER):
      case key.toString().endsWith(LocalStorageKeyTypes.STRING):
        value = Base64.encode(`${value}`);
        localStorage.setItem(key, '' + value);
        break;
      case key.toString().endsWith(LocalStorageKeyTypes.JSON):
        try {
          let json: string = JSON.stringify(value);
          json = Base64.encode(json);
          localStorage.setItem(key, json);
        } catch (e) {
          Logger.error(`LocalStorageService: Object could not be stringified`, value);
        }
        break;
    }
  }

  /**
   * Removes key and value from local storage
   * @param key Name of key
   */
  public static remove(key: LocalStorageKeys): void {
    localStorage.removeItem(key);
  }

  /**
   * Returns value of given key
   * @param key Name of key
   */
  public static get<T extends string | number | boolean | object>(key: LocalStorageKeys): T | null {
    let value = localStorage.getItem(key);

    if (value === null) {
      return null;
    }

    // Decode the value since the localStorage has encoded values
    value = Base64.decode(value);

    switch (true) {
      case key.toString().endsWith(LocalStorageKeyTypes.BOOLEAN):
        return (value === 'true') as T;
      case key.toString().endsWith(LocalStorageKeyTypes.NUMBER):
        return +value as T;
      case key.toString().endsWith(LocalStorageKeyTypes.STRING):
        return ('' + value) as T;
      case key.toString().endsWith(LocalStorageKeyTypes.JSON):
        try {
          return JSON.parse(value) as T;
        } catch (e) {
          Logger.error(`LocalStorageService: Given value is not valid json`, value);
          return {} as T;
        }
      default:
        return value as T;
    }
  }
}
