import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Service that listens to the `visibilitychange` event and provides a visibility state via an observable
 * True, if the window/tab is active, otherwise false
 */
@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  /** Whether current tab is active at the moment */
  private visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(document.visibilityState === 'visible');
  /** Whether current tab is active at the moment */
  public visible$: Observable<boolean> = this.visible.asObservable();

  constructor() {
    // Listen to visibility changes of the current window/tab
    document.addEventListener('visibilitychange', () => this.visible.next(document.visibilityState === 'visible'));
  }
}
