import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { TranslocoStatusService } from '@utilityServices';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translocoService: TranslocoService, private translocoStatusService: TranslocoStatusService) {
    // Subscribe to events of the transloco service here to get all events and
    // update the status in the TranslocoStatusService which is then provided as BehaviorSubject
    translocoService.events$.subscribe((event) => {
      this.translocoStatusService.setStatus(false, event.payload.langName);
    });
  }
}
