import { HttpParams } from '@angular/common/http';

import { BaseBackendService } from '@http/base-backend.service';
import { IApiResponse } from '@models';
import { IPaginationParams } from '@models';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { BaseDataManager, IBaseDataManagerOptions } from './base.dataManager';
import { IDataCache } from './dataCache';

/** Default page size */
export const DEFAULT_PAGE_SIZE = 50;

/**
 * Data Manager Options for Arrays
 */
export interface IArrayDataManagerOptions<T, O extends ArrayLike<unknown>> extends IBaseDataManagerOptions<T, O> {
  /** Caching technique */
  dataCache?: IDataCache<T>;

  /** Pagination parameters. Omit to disable pagination */
  pagination?: {
    pageSize?: number;
  };
  /** Track by function determines by what property objects should be compared by. Used for caching */
  trackBy?: (a: T) => string;
}

/**
 * Implementation of DataManager for type Model<T> Arrays
 * T stands for the type of data
 * O stands for the returned value types of the observables array
 */
export class ArrayDataManager<T, O extends ArrayLike<unknown>> extends BaseDataManager<T[], T, O> {
  /** Pagination values */
  private pagination: IPaginationParams;

  /**
   * Array data manager
   * @param options Options for the DataManager
   * @param backendService Backend service that should be used for the requests
   */
  constructor(protected options: IArrayDataManagerOptions<T, O>, protected backendService: BaseBackendService) {
    super(options, backendService);
  }

  /**
   * Initializes pagination for new requests
   */
  protected prepareNewRequest(): void {
    this.initPaginationValues();
  }

  /**
   * Gets data from cache or if forced or unavailable from the server
   * @param debounce whether requests should be debounced
   * @param force will ignore cache if set to true
   */
  protected getData(debounce: boolean = false, force: boolean = false): Promise<IApiResponse<T[]>> {
    if (force || this.markedForChange) {
      return this.getDataFromServer(debounce).then((data) => {
        // Save new data, we don't want to wait whether caching succeeds at this point
        this.options.dataCache.setList(this.generateCachingIdentifier(), data).catch(() => null);

        return data;
      });
    }

    // Get list data from cache
    return (
      this.options.dataCache
        .getList(this.generateCachingIdentifier())
        .then((response) => {
          response.meta.isCached = true;
          return response;
        })
        // Then try loading the data from server
        .catch(() => {
          return this.getDataFromServer(debounce).then((data) => {
            // Save new data
            this.options.dataCache.setList(this.generateCachingIdentifier(), data).catch(() => null);

            return data;
          });
        })
    );
  }

  /**
   * Generates an identifier for storing data in a cache using the current URL and parameters.
   */
  private generateCachingIdentifier(): string {
    const pageId: (string | number)[] = [];
    const paramId: (string | number)[] = [];

    // include pagination in params
    if (this.options.pagination) {
      pageId.push('');
      pageId.push('p');
      pageId.push(this.pagination.pageSize);
      pageId.push(this.pagination.page);
    }

    // include further parameters
    if (this.requestParams) {
      Object.keys(this.requestParams).forEach((key) => {
        if (this.requestParams[key]) {
          paramId.push(`${key}=${this.requestParams[key]}`);
        }
      });
    }

    return this.serviceUrl.join('/') + pageId.join('/') + (paramId.length ? '?' + paramId.join('&') : '');
  }

  /**
   * Creates http params for the main request
   * @returns Http params
   */
  protected createRequestParams(): HttpParams {
    if (!this.options.pagination && !this.requestParams) {
      return undefined;
    }

    const allParams: { [param: string]: string | string[] } = {
      ...(this.requestParams || {}),
      // tslint:disable-next-line: no-any
      ...((this.pagination as any) || {}),
    };
    return new HttpParams({ fromObject: allParams });
  }

  // ----- PAGINATION ----- //
  /**
   * Sets the default values for pagination
   */
  protected initPaginationValues(): void {
    if (!this.options.pagination) {
      return;
    }

    this.pagination = {
      page: 1,
      pageSize: this.options.pagination.pageSize || DEFAULT_PAGE_SIZE,
    };
  }

  /**
   * Repopulates the data$ observable with new data for the given page.
   * This will not allow stacking page data for continuous scrolling.
   * @param page page number to load
   * @param pageSize sets a new page size for this and all further requests
   */
  public async loadPage(page: number, pageSize?: number): Promise<IApiResponse<T[]>> {
    if (!this.options.pagination) {
      Logger.warn('DataManager', '|', 'Cannot load Page', page, 'with size', pageSize, '. Pagination is disabled');
      return undefined;
    }

    if (pageSize) {
      this.options.pagination.pageSize = pageSize;
    }

    this.pagination.pageSize = this.options.pagination.pageSize;
    this.pagination.page = page;

    const newData = await this.getData(true);

    if (Array.isArray(newData.data)) {
      this.data.next(newData);
      return newData;
    } else {
      throw new Error('Data has to be an array');
    }
  }

  /**
   * Loads the next page and adds it to the current data.
   * Allows endless scrolling techniques.
   * @returns Promise that resolves when data is loaded
   */
  public async loadNextPage(): Promise<void> {
    if (!this.options.pagination) {
      return;
    }

    const isLastPage: boolean = (this.data.value.meta.page || 1) === (this.data.value.meta.totalPages || 1);

    // If current page is the last page, do nothing
    if (isLastPage) {
      return;
    }
    // Only count page up if we aren't already running a request (to prevent skipping)
    if (!this.request) {
      this.pagination.page += 1;
    }
    const newData: IApiResponse<T[]> = await this.getDataFromServer();
    const currentData: IApiResponse<T[]> =
      this.data.value && this.data.value.data ? { ...this.data.value, meta: newData.meta } : { data: [] };

    if (Array.isArray(currentData.data) && Array.isArray(newData.data)) {
      currentData.data.push(...newData.data);
      this.data.next(currentData);
    } else {
      throw new Error('Data has to be an array');
    }
  }
}
