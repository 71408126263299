import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseBackendService } from '@http/base-backend.service';
import { IApiResponse } from '@models';
import { KebUrl } from '@serviceUrls';
import { BaseDataManager, IBaseDataManagerOptions } from './base.dataManager';
import { IDataCache } from './dataCache';

/**
 * Data Manager Options for basic Objects
 */
export interface IDetailsDataManagerOptions<T, O extends ArrayLike<unknown>> extends IBaseDataManagerOptions<T, O> {
  /** Caching technique */
  dataCache?: IDataCache<T>;

  /** Track by function determines by what property objects should be compared by. Used for caching */
  trackBy?: (a: T) => string;

  /** Update contains means to set the service url and change it dynamically depending on observables */
  update: {
    /** Id for details requests. Will also be used to retrieve single items from a shared cache */
    detailsId: Observable<string>;
    /** Update service url when any observable emits */
    observables?: Observable<unknown>[];
    /**
     * Function that updates the url of the request,
     * contains the details id as a first item.
     * Called once initially with only the details ID,
     * then when observables update with their data as an array.
     * Remember to check whether the data you require is actually set.
     */
    updateFn: (data: O) => KebUrl;
  };
}

/**
 * Implementation of DataManager for type Model<T>, as a details request
 * T stands for the type of data
 * O stands for the returned value types of the observables array
 */
export class DetailsDataManager<T, O extends ArrayLike<unknown>> extends BaseDataManager<T, T, O> {
  /** Id of the requested details source */
  private detailsId: string;

  /**
   * Details data manager
   * @param options Options for the DataManager
   * @param backendService Backend service that should be used for the requests
   */
  constructor(protected options: IDetailsDataManagerOptions<T, O>, protected backendService: BaseBackendService) {
    super(options, backendService);
  }

  protected initOptionValues(options: IDetailsDataManagerOptions<T, O>): void {
    super.initOptionValues(options);

    if (Array.isArray(options.update.observables)) {
      options.update.observables = [options.update.detailsId, ...options.update.observables];
    } else {
      options.update.observables = [options.update.detailsId];
    }

    // save details Id
    // TODO: (Steffen) Check if this could be accomplished by `options.update.detailsId.pipe(tap(detailsId => this.detailsId = detailsId))` above
    options.update.detailsId.pipe(takeUntil(options.destroy)).subscribe((id) => (this.detailsId = id));
  }

  /**
   * Gets data from cache or if forced or unavailable from the server
   */
  protected getData(debounce: boolean = false, force: boolean = false): Promise<IApiResponse<T>> {
    if (force || this.markedForChange) {
      return this.getDataFromServer(debounce).then((response) => {
        // Save new data, don't wait though
        this.options.dataCache.setItem(response.data).catch();

        return response;
      });
    }

    // Get list data from cache
    return (
      this.options.dataCache
        .getItem(this.detailsId)
        .then((data) => ({ data, meta: { isCached: true } } as IApiResponse<T>))
        // Or try to get the same data from server
        .catch(() => {
          return this.getDataFromServer(debounce).then((response) => {
            // Save new data
            this.options.dataCache.setItem(response.data).catch();

            return response;
          });
        })
    );
  }
}
