import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { PUBLIC_URLS } from '@navUrls';
import { AuthService, UserService } from '@services';

/**
 * Prevents user to access pages until user data is available
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private userService: UserService) {}

  /**
   * Checks if user data is available = user is logged in
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.userService.getUser();

    // If no user data is currently available, the user is not logged in -> no access to the current page
    if (!user) {
      this.navigateToLogin();
      return false;
    }
    return true;
  }

  /**
   * Navigates to the login page
   */
  private navigateToLogin(): void {
    this.router.navigate(PUBLIC_URLS.authentication.getUrlSegments()).then();
  }
}
