import { HIGH_RISK_COUNTRY_LIST_NAME } from '@shared/enums/high-risk-country.enum';
import { ICheck } from './check.model';
import { ICompany } from './company.model';
import { ISOCountryCode } from './country.model';
import { IDecision } from './decision.model';
import { IExtendedKycFile, IKycFile } from './kycfile.model';
import { IMetaModel, MetaModel } from './meta.model';
import { OrderStatus } from './order-status.model';
import { Result } from './result.model';

/**
 * Available order types
 */
export enum OrderType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}

export enum Partnership {
  CONTINUOUS_STANDARD_PURPOSE = 'CONTINUOUS_STANDARD_PURPOSE',
  CONTINUOUS_SPECIAL_PURPOSE = 'CONTINUOUS_SPECIAL_PURPOSE',
  TRANSACTION = 'TRANSACTION',
}

export enum PartnershipFlagType {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  NO_VALUE = 'NO_VALUE',
}

/**
 * An order is the core that is created by a user to check his contractor
 */
export interface IOrder extends IMetaModel {
  /** Id of the order */
  orderId: string;
  /** Type of the order */
  type: OrderType;

  typeOfPartnership: Partnership | null;

  partnershipPurposeBasedOn: string | null;

  partnershipIsComplex: PartnershipFlagType;

  partnershipHasHighRisk: PartnershipFlagType;

  partnershipHasForeignConnection: PartnershipFlagType;

  partnershipAffectedCountry: ISOCountryCode | null;

  partnershipAffectedHitlists: HIGH_RISK_COUNTRY_LIST_NAME[] | null;
  /** Id of the company */
  companyId: string;
  /** Related company */
  company: ICompany;
  /** Id of the user that has created the order */
  createdByUserId: string;
  /** Actors that are related to this order */
  checks?: ICheck[];

  isComplex?: boolean;
  /** Current status of the order */
  status: OrderStatus;
  /** List of decisions */
  decisions?: IDecision[];
  /** Result of the order. Composed of the results of the decisions. */
  result?: Result;
  /** User id of the assignee */
  assigneeUserId?: string;

  /** Free text for internal notes */
  osint?: string;
  /** Additional files */
  kycFiles?: IKycFile[];
  /**
   * The manual KYC report, if any.
   */
  manualKYCReport: IKycFile | IExtendedKycFile;
}

/**
 * An order is the core that is created by a user to check his contractor
 */
export class Order extends MetaModel implements IOrder, IMetaModel {
  /** Id of the order */
  public orderId: string;

  /** Type of the order */
  public type: OrderType;

  public typeOfPartnership: Partnership | null;

  public partnershipPurposeBasedOn: string | null;

  public partnershipIsComplex: PartnershipFlagType;

  public partnershipHasHighRisk: PartnershipFlagType;

  public partnershipHasForeignConnection: PartnershipFlagType;

  public partnershipAffectedCountry: ISOCountryCode | null;

  public partnershipAffectedHitlists: HIGH_RISK_COUNTRY_LIST_NAME[] | null;

  /** Id of the company */
  public companyId: string;
  /** Related company */
  public company: ICompany;
  /** Id of the user that has created the order */
  public createdByUserId: string;
  /** Actors that are related to this order */
  public checks?: ICheck[];

  public isComplex?: boolean;

  /** Current status of the order */
  public status: OrderStatus;
  /** List of decisions */
  public decisions?: IDecision[];
  /** Result of the order. Composed of the results of the decisions. */
  public result: Result | null;

  public assigneeUserId?: string;
  /** Free text for internal notes */
  public osint?: string;

  public kycFiles?: IKycFile[];
  /**
   * The manual KYC report, if any.
   */
  public manualKYCReport: IKycFile | IExtendedKycFile;
}
