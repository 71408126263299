import { IActor } from './actor.model';

/**
 * Relationship between two actors
 */
export type IActorToActor =
  | IActorToActorBase<Exclude<ActorRelationshipType, ActorRelationshipType.IS_SHAREHOLDER_OF>>
  | IActorToActorShareholder;

/**
 * Is either an IActor or only an IActor's Id
 */
export type IActorOrId = IActor | Pick<IActor, 'actorId'>;

/**
 * Base actor-to-actor relationship
 */
interface IActorToActorBase<ART extends ActorRelationshipType> {
  /** Id of the relationship between two actors */
  actorToActorId: string;
  /** The actor that has a relationship to another actor */
  actor: IActor;
  /** Type of the relationship */
  relationshipType: ART;
}

/**
 * Special case: If the relationshipType is `IS_SHAREHOLDER_OF` the `typeOfControl` is required.
 */
interface IActorToActorShareholder extends IActorToActorBase<ActorRelationshipType.IS_SHAREHOLDER_OF> {
  /** Type of control */
  typeOfControl: TypeOfControl;
}

/**
 * Available relationship types for actor-to-actor relationships
 */
export enum ActorRelationshipType {
  /** Actor is shareholder of the corporate ("Wirtschaftlich Berechtigter") */
  IS_SHAREHOLDER_OF = 'IS_SHAREHOLDER_OF',
  /** Actor is the CEO of the corporate */
  IS_CEO_OF = 'IS_CEO_OF',
  /** Actor is legal representative of the corporate ("Gesetzlicher Vertreter") */
  IS_LEGAL_REPRESENTATIVE_OF = 'IS_LEGAL_REPRESENTATIVE_OF',
}

/**
 * Available control types
 */
export enum TypeOfControl {
  /** German: "Mehr als 25 % der Kapitalanteile" */
  MORE_THAN_25_CAPITAL_SHARES = 'MORE_THAN_25_CAPITAL_SHARES',
  /** German: "Mehr als 25 % der Stimmrechte" */
  MORE_THAN_25_VOTING_RIGHTS = 'MORE_THAN_25_VOTING_RIGHTS',
  /** German: "Kontrolle in vergleichbarer Weise" */
  CONTROL_IN_COMPARABLE_MANNER = 'CONTROL_IN_COMPARABLE_MANNER',
  /** German: "Veranlassung der Transaktion" */
  INITIATING_THE_TRANSACTION = 'INITIATING_THE_TRANSACTION',
  /** German: "Fiktiver wirtschaftlich Berechtigter" */
  NOTIONAL_BENEFICIAL_OWNER = 'NOTIONAL_BENEFICIAL_OWNER',
}

/**
 * Relationship between two actors
 */
export class ActorToActor<ART extends ActorRelationshipType> implements IActorToActorBase<ART> {
  /** Id of the relationship between two actors */
  public readonly actorToActorId: string;
  /** Type of control */
  public typeOfControl: ART extends ActorRelationshipType.IS_SHAREHOLDER_OF ? TypeOfControl : never;

  /**
   * @param actor The actor that has a relationship to another actor
   * @param relationshipType Type of the relationship
   */
  constructor(public readonly actor: IActor, public readonly relationshipType: ART) {}
}
