import { HIGH_RISK_COUNTRY_LIST_NAME } from '@shared/enums/high-risk-country.enum';
import { ISOCountryCode } from './country.model';
import { Result } from './result.model';

/**
 * Decisions can be considered as the status of a KYC order.
 * It also contains, among other things, reason(s) if the order contains warnings
 */
export type IDecision = IDecisionBase<'system'> | IDecisionBase<'manual'>;

/**
 * Base decision interface.
 * Depending on `decisionTakenBy` the `decisionTakenByUserId` needs to be set.
 */
interface IDecisionBase<T extends 'system' | 'manual'> {
  /** Id of the decision */
  decisionId: string;
  /** Id of the related order */
  orderId: string;
  /** Result of the decision */
  result: Result;
  /** Creation date of the decision */
  createdAt: string;
  /** Whether the decision was taken automatically by the system or manual by a support user */
  decisionTakenBy: T;
  /** Id of the user, that has made the decision. Only available if `decisionTakenBy` is 'manual' */
  decisionTakenByUserId: T extends 'system' ? null : string;
  /** Decision details */
  details: IDecisionDetail[];
}

/** Includes regulatory lists the country was found upon */
export interface IHighRiskCountryEvidence {
  country: ISOCountryCode;
  lists: HIGH_RISK_COUNTRY_LIST_NAME[];
}

/**
 * Decision details
 */
export interface IDecisionDetail {
  /** Reason for the decision */
  reason: Reason;
  /** Related country */
  country?: ISOCountryCode;
  highRiskCountryEvidence?: IHighRiskCountryEvidence;
  /** Id of affected Actor */
  affectedActorId?: string;
}

/**
 * Available reasons for warnings
 */
export enum Reason {
  HIGH_RISK_COUNTRY = 'HIGH_RISK_COUNTRY',
  PEP = 'PEP',
  SANCTION_LIST = 'SANCTION_LIST',
  COMPLEX_PARTNERSHIP = 'COMPLEX_PARTNERSHIP',
  HIGH_RISK_PARTNERSHIP = 'HIGH_RISK_PARTNERSHIP',
  FOREIGN_CONNECTION_PARTNERSHIP = 'FOREIGN_CONNECTION_PARTNERSHIP',
}
