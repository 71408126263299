import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { BaseBackendService } from './base-backend.service';

/**
 * Backend service for requests to the Backend for Frontend
 */
@Injectable({
  providedIn: 'root',
})
export class AdminBffService extends BaseBackendService {
  constructor(protected http: HttpClient) {
    super(environment.backendUrl);
  }
}
