import { Injectable } from '@angular/core';
import { AdminBffService } from '@http/admin-bff.service';
import { UnsubscribeService } from '@kerberos-compliance/kerberos-fe-lib';
import { IUser } from '@models';
import { UserUrl } from '@serviceUrls';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UnsubscribeService {
  /** User data as behavior subject */
  private user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);

  /** User data as observable */
  public user$: Observable<IUser> = this.user.asObservable();

  /** Request used for user/me route */
  private userMeRequest: Observable<IUser>;

  constructor(private adminBffService: AdminBffService) {
    super();
  }

  /**
   * Returns the user object once. To get any user object change use the `user observable`
   * @param forceReload If true, user will be loaded from server. Otherwise cached response is returned
   * @returns User as Promise
   */
  public async getUser(forceReload: boolean = false): Promise<IUser> {
    const newUserData = forceReload || !this.userMeRequest;

    // If new
    if (newUserData) {
      this.userMeRequest = this.adminBffService.get<IUser>({ url: UserUrl.getMe() }).pipe(
        shareReplay(), // Create request with caching
        map((response) => response?.data)
      );
    }

    let user: IUser;
    try {
      user = await this.userMeRequest.toPromise();
    } catch (e) {
      user = null;
    }

    // Updates user only if force reload or any value in user object has changed
    if (forceReload || JSON.stringify(this.user.value) !== JSON.stringify(user)) {
      this.user.next(user);
    }

    return user;
  }

  /**
   * Invalidates the user data
   */
  public invalidateUser(): void {
    this.userMeRequest = null;
    this.user.next(null);
  }

  /**
   * Loads a single user from the backend
   * @param companyId Id of the user's company
   * @param userId Id of the user
   */
  public async getSingleUser(companyId: string, userId: string): Promise<IUser> {
    return this.adminBffService
      .get<IUser>({
        url: UserUrl.getSingleUser(companyId, userId),
      })
      .pipe(
        takeUntil(this.destroyed),
        map((response) => response.data)
      )
      .toPromise();
  }
}
