// tslint:disable:interface-name
import { Route } from '@angular/router';

/** KebRoute array type */
export declare type KebRoutes = KebRoute[];

/**
 * KebRoute customizes the Angular Route for more type safety
 */
export interface KebRoute extends Route {
  /**
   * Additional developer-defined data provided to the component via
   * `ActivatedRoute`. By default, no additional data is passed.
   */
  data?: KebRouteData;
  /**
   * An array of child `Route` objects that specifies a nested route
   * configuration.
   */
  children?: KebRoutes;
}

/**
 * Data that can be provided within routes
 */
export interface KebRouteData {
  /** Page title */
  title?: string | string[];
}
// tslint:enable:interface-name
