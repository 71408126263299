import { ICheck } from '@models';
import { IActorDocument } from './actor-document.model';
import { IActorToActor } from './actor-to-actor.model';
import { CorporateActorDetails, ICorporateActorDetails } from './corporate-actor-details.model';
import { IIndividualActorDetails, IndividualActorDetails } from './individual-actor-details.model';
import { IMetaModel, MetaModel } from './meta.model';

/**
 * Actors can be persons or companies
 */
export interface IActor extends IMetaModel {
  /** Id of the actor */
  actorId: string;
  /** Type of the actor */
  type: ActorType;
  /** Details of the actor if it is a corporate actor */
  corporateDetails?: ICorporateActorDetails;
  /** Details of the actor if it is a individual actor */
  individualDetails?: IIndividualActorDetails;
  /** List of actor to actor relationships */
  relatedActors: IActorToActor[];
  /** Is actor already verified? */
  isVerified?: boolean;
}

export interface IDetailEntities {
  check: ICheck;
  actor: IActor;
  actorToActor?: IActorToActor;
}
/**
 * Available actor types
 */
export enum ActorType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}

/**
 * Actors can be persons or companies
 */
export class Actor extends MetaModel implements IActor {
  /** Id of the actor */
  public readonly actorId: string;
  /** Corporate details of the actor */
  public readonly corporateDetails?: ICorporateActorDetails;
  /** Individual details of the actor */
  public readonly individualDetails?: IIndividualActorDetails;
  /** All related documents of this actor */
  public documents?: IActorDocument[];
  /** List of actor to actor relationships */
  public relatedActors: IActorToActor[] = [];

  /**
   * @param type Type of the actor
   */
  constructor(public readonly type: ActorType) {
    super();

    switch (type) {
      case ActorType.CORPORATE:
        this.corporateDetails = new CorporateActorDetails();
        break;
      case ActorType.INDIVIDUAL:
        this.individualDetails = new IndividualActorDetails();
        break;
    }
  }
}
