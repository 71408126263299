import { Observable } from 'rxjs';

import { ApiError } from '@models';

/**
 * Catches api errors and prevents calling the `next` callback
 * @example
 *    exampleObservable$.pipe(
 *      catchApiError((error) => {
 *        // Handle ApiError here
 *      })
 *    ).subscribe(({ data }) => {
 *      // Handle valid data here
 *    });
 */
export function catchApiError<T>(handleErrorFn?: (error: ApiError) => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>) => {
    return new Observable<T>((observer) => {
      return source.subscribe({
        next(response: T | ApiError): void {
          if (!(response instanceof ApiError)) {
            observer.next(response);
            return;
          }

          if (handleErrorFn) {
            handleErrorFn(response);
          }
        },
        error(err: Error): void {
          observer.error(err);
        },
        complete(): void {
          observer.complete();
        },
      });
    });
  };
}
