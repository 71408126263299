import { HttpErrorResponse } from '@angular/common/http';

/**
 * Api error
 */
export class ApiError {
  /** Message of error */
  public readonly message: string;
  /** Reason of error */
  public readonly code: number;

  /**
   * Api error
   * @param error Error type
   */
  constructor(error: HttpErrorResponse) {
    this.message = error?.error;
    this.code = error?.status;
  }
}
