import { ISOCountryCode } from './country.model';
import { IMetaModel, MetaModel } from './meta.model';

/**
 * Generic address type with all necessary address information
 */
export interface IActorAddress extends IMetaModel {
  /** Id of the address */
  actorAddressId: string;
  /** Optional name of the address */
  name?: string;
  /** Street of the address */
  street: string;
  /** House number of the address */
  houseNumber: string;
  /** Additional information of the address */
  additional?: string;
  /** Zip code of the address */
  zipCode: string;
  /** City of the address */
  city: string;
  /** State of the address */
  state?: string;
  /** The three letter country code base on ISO 3166-1 Alpha3 */
  countryCode?: ISOCountryCode;
  /** Optional date from which the date is valid */
  validFrom?: string;
  /** Optional date until which the date is valid */
  validUntil?: string;
}

/**
 * Generic address with all necessary address information
 */
export class ActorAddress extends MetaModel implements IActorAddress {
  /** Id of the address */
  public actorAddressId: string;
  /** Optional name of the address */
  public name?: string;
  /** Street of the address */
  public street: string;
  /** House number of the address */
  public houseNumber: string;
  /** Additional information of the address */
  public additional?: string;
  /** Zip code of the address */
  public zipCode: string;
  /** City of the address */
  public city: string;
  /** State of the address */
  public state?: string;
  /** The three letter country code base on ISO 3166-1 Alpha3 */
  public countryCode?: ISOCountryCode;
  /** Optional date from which the date is valid */
  public validFrom?: string;
  /** Optional date until which the date is valid */
  public validUntil?: string;
}
