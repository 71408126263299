import { IApiResponse } from '@models';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IDataCache, IDataCacheOptions } from './dataCache';

/**
 * Caches data in a variable
 */
export class RamDataCache<T> implements IDataCache<T> {
  /** Data cache for items */
  private dataCache: Map<string, T> = new Map();
  /** Data cache for lists */
  private listCache: Map<string, IApiResponse<string[]>> = new Map();

  /**
   * Constructor takes a trackby function
   */
  constructor(private options: IDataCacheOptions<T>) {}

  /**
   * Returns an item from the cache
   * @param identifier identifier to get items by, determined by trackBy
   */
  public getItem(identifier: string): Promise<T> {
    return new Promise((resolve, reject) => {
      const data = this.dataCache.get(identifier);
      if (data) {
        Logger.log('uncached data for ', identifier);
        resolve(data);
      } else {
        Logger.log('couldnt uncache ', identifier);
        reject(data);
      }
    });
  }

  /**
   * Saves the given item to the cache
   * @param item item to save
   */
  public setItem(item: T): Promise<void> {
    const id = this.options.trackBy(item);

    return new Promise((resolve, reject) => {
      if (!id) {
        reject();
        return;
      }
      Logger.log('caching ', item);
      this.dataCache.set(id, item);
      resolve();
    });
  }

  /**
   * Saves a list of items for the given identifier, usually the url
   * @param identifier identifier to save list by
   * @param response items to save
   */
  public setList(identifier: string, response: IApiResponse<T[]>): Promise<void> {
    if (!identifier || !response || !response.data || !response.data.length) {
      return Promise.reject();
    }

    // Get item ids
    const ids = response.data.map((item) => {
      this.setItem(item).catch();
      return this.options.trackBy(item);
    });
    // save list of ids
    this.listCache.set(identifier, { ...response, data: ids });

    Logger.log('cache list ', identifier);

    return Promise.resolve();
  }

  /**
   * Returns a list of items for the given URL from Cache
   * @param identifier id of the list, usually the URL
   */
  public getList(identifier: string): Promise<IApiResponse<T[]>> {
    const response = this.listCache.get(identifier);
    if (!response) {
      return Promise.reject(null);
    }

    // combined request for all list items, should fail if any of them fails
    const itemRequests = response.data.map((id) => this.getItem(id));

    return Promise.all(itemRequests)
      .then((items) => {
        return { ...response, data: items };
      })
      .catch((items) => {
        this.listCache.delete(identifier);

        Logger.log('uncached data for list ', identifier);
        return items;
      });
  }

  /**
   * Clears all data from cache
   */
  public clearData(): Promise<void> {
    Logger.log('cache cleared');

    this.dataCache.clear();
    return Promise.resolve();
  }

  /**
   * Clears all list data, but leaves item data in place
   */
  public clearLists(): Promise<void> {
    Logger.log('list cache cleared');
    this.listCache.clear();

    return Promise.resolve();
  }
}
