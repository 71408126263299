import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { KebRoutes } from '@models';

/** App routes */
export const APP_ROUTES: KebRoutes = [
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
/** App routes */
export const appRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(APP_ROUTES, {
  useHash: false,
  // Provides always all url params even in child components
  paramsInheritanceStrategy: 'always',
  scrollPositionRestoration: 'disabled',
  // Doesn't actually reload the page but runs navigation events again. Used for backToTop on reload.
  onSameUrlNavigation: 'reload',
});
