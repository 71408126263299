import { SafeResourceUrl } from '@angular/platform-browser';
import { MIME_TYPE } from './mime-type';
import { IOrder } from './order.model';

export enum KycFileType {
  Order = 'Order',
  Manual_KYC_Report = 'Manual_KYC_Report',
}

export interface IKycFile {
  kycFileId: string;
  itemId: string;
  fileURL: string;
  fileType: KycFileType;
  mimeType: MIME_TYPE;
  fileName: string;
  order: IOrder;
}

/**
 * Extended kyc-file interface
 */
export interface IExtendedKycFile extends IKycFile {
  /** Sanitized url to prevent errors by including unsafe urls in the template */
  sanitizedUrl: SafeResourceUrl;
  /** Size of the file */
  fileSize: string;
}
