import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AdminBffService, ArrayDataManager, DataManagerService, DetailsDataManager } from '@http';
import {
  IActor,
  IActorAddress,
  IActorDocument,
  IDecision,
  IDecisionDetail,
  IFile,
  IKycFile,
  IOrder,
  ISOCountryCode,
  IUser,
  KycFileType,
  MIME_TYPE,
  OrderStatus,
  Partnership,
  PartnershipFlagType,
} from '@models';
import { UserService } from '@services/user.service';
import { OrderUrl } from '@serviceUrls';
import { HIGH_RISK_COUNTRY_LIST_NAME } from '@shared/enums/high-risk-country.enum';

export interface IUpdateOrder {
  status?: OrderStatus;
  assigneeUserId?: string;
  osint?: string;
  typeOfPartnership?: Partnership | null;
  partnershipPurposeBasedOn?: string | null;
  partnershipIsComplex?: PartnershipFlagType;
  partnershipHasHighRisk?: PartnershipFlagType;
  partnershipHasForeignConnection?: PartnershipFlagType;
  partnershipAffectedCountry?: ISOCountryCode | null;
  partnershipAffectedHitlists?: HIGH_RISK_COUNTRY_LIST_NAME[] | null;
}

/**
 * Service that handles the order data and the related requests
 */
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  /** Data manager for orders */
  public orders: ArrayDataManager<IOrder, [IUser]>;
  /** Data manager for the current order */
  public currentOrder: DetailsDataManager<IOrder, [string, IUser]>;

  /** Current order id as BehaviorSubject */
  private orderId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  /** Id of the company the order is related to */
  private companyId: string;

  constructor(
    private dataManagerService: DataManagerService,
    private userService: UserService,
    private adminBffService: AdminBffService
  ) {
    this.orders = dataManagerService.createArrayDataManager({
      pagination: {
        pageSize: 50,
      },
      update: {
        // Reload data if user has changed
        observables: [userService.user$],
        updateFn: (user) => (!!user ? OrderUrl.getAllOrders() : null),
      },
    });

    this.currentOrder = dataManagerService.createDetailsDataManager({
      update: {
        detailsId: this.orderId$,
        // Reload data if user has changed
        observables: [userService.user$],
        updateFn: ([orderId]) => (orderId ? OrderUrl.getSingleOrder(this.companyId, orderId) : null),
      },
    });
  }

  /**
   * Updates the ids for the current order to trigger the loading of the corresponding data
   * @param orderId New order id
   * @param companyId New company id
   */
  public updateCurrentIds(orderId: string, companyId: string): void {
    if (orderId !== this.orderId$.value || companyId !== this.companyId) {
      this.companyId = companyId;
      this.orderId$.next(orderId);
    }
  }

  /**
   * Sends a request to update the current order
   * @param orderData Order data that should be updated
   */
  public async updateOrder(orderData: IUpdateOrder, refresh = true): Promise<void> {
    await lastValueFrom(
      this.adminBffService
        .patch({
          url: OrderUrl.updateOrder(this.companyId, this.orderId$.value),
          body: orderData,
        })
        .pipe(map((response) => response.data))
    );
    if (refresh) {
      this.orders.markDataAsChanged();
      await this.currentOrder.dataHasChanged();
    }
  }

  /**
   * Sends a request to update the given actor
   * @param actorId Id of the actor
   * @param actor The changes that should be made to the actor
   */
  public updateActor(actorId: string, actor: Partial<IActor>): Promise<IActor> {
    return this.adminBffService
      .patch<IActor>({ url: OrderUrl.updateActor(actorId), body: actor })
      .pipe(
        map((response) => response.data),
        tap(() => {
          this.orders.markDataAsChanged();
          this.currentOrder.dataHasChanged();
        })
      )
      .toPromise();
  }

  /**
   * Sends a request to update the given actor address
   * @param checkId Id of the check, that contains the actorAddress
   * @param actorAddressId Id of the actor address
   * @param actorAddress The changes that should be made to the address
   */
  public updateActorAddress(
    checkId: string,
    actorAddressId: string,
    actorAddress: Partial<IActorAddress>
  ): Promise<IActorAddress> {
    return this.adminBffService
      .patch<IActorAddress>({
        url: OrderUrl.updateActorAddress(this.companyId, this.orderId$.value, checkId, actorAddressId),
        body: actorAddress,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Sends a request to add a new decision with given details
   * @param decisionDetails Details of the decision
   */
  public async addDecisions(decisionDetails: IDecisionDetail[]): Promise<IDecision> {
    return lastValueFrom(
      this.adminBffService
        .post<IDecision>({
          url: OrderUrl.addDecision(this.companyId, this.orderId$.value),
          body: { details: decisionDetails },
        })
        .pipe(map((response) => response.data))
    );
  }

  /**
   * Provides a list of documents for a given check
   * @param checkId Id of the check, the document should be added to
   * @param actorDocuments List of documents that should be provided
   */
  public async provideDocuments(checkId: string, actorDocuments: IActorDocument[]): Promise<IActorDocument[]> {
    const promises = await actorDocuments.map(
      async (actorDocument) =>
        await this.adminBffService
          .post<IActorDocument>({
            url: OrderUrl.provideDocuments(this.companyId, this.orderId$.value, checkId),
            body: actorDocument,
          })
          .pipe(map((response) => response.data))
          .toPromise()
    );

    return await Promise.all(promises);
  }

  /**
   * Deletes a list of documents
   * @param checkId Id of the check, the document is associated with
   * @param documentIds List of document ids that should be deleted
   */
  public async deleteDocuments(checkId: string, documentIds: string[]): Promise<void> {
    const promises = documentIds.map(
      async (documentId) =>
        await this.adminBffService
          .delete<void>({
            url: OrderUrl.deleteDocument(this.companyId, this.orderId$.value, checkId, documentId),
          })
          .toPromise()
    );

    await Promise.all(promises);
  }

  /**
   * Loads all files of the given document
   * @param companyId Id of the company
   * @param orderId Id of the order
   * @param checkId Id of the check
   * @param documentId Id of the document
   */
  public async getFilesOfDocuments(
    companyId: string,
    orderId: string,
    checkId: string,
    documentId: string
  ): Promise<IFile[]> {
    return await this.adminBffService
      .get<IFile[]>({
        url: OrderUrl.getAllFilesOfDocument(companyId, orderId, checkId, documentId),
        params: {
          include: [
            MIME_TYPE.GIF,
            MIME_TYPE.JPG,
            MIME_TYPE.PNG,
            MIME_TYPE.PLAIN_TEXT,
            MIME_TYPE.MP4,
            MIME_TYPE.XML,
            MIME_TYPE.PDF,
          ],
        },
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  public async uploadKycFile(companyId: string, orderId: string, content: FormData): Promise<IKycFile> {
    return this.adminBffService
      .post({ url: OrderUrl.uploadKycFile(companyId, orderId), body: content })
      .pipe(map((response) => response.data as IKycFile))
      .toPromise();
  }

  /**
   * Request the deletion of the kyc-file with the given kycFileId
   */
  public async deleteKycFile(companyId: string, orderId: string, kycFileId: string): Promise<boolean> {
    return await this.adminBffService
      .delete<{ wasDeleted: boolean }>({ url: OrderUrl.kycFile(companyId, orderId, kycFileId) })
      .pipe(map((response) => response.data.wasDeleted))
      .toPromise();
  }

  /**
   * Requests the kyc-file with the given kycFileId and specific kycFileType
   */
  public async getKycFile(
    companyId: string,
    orderId: string,
    kycFileId: string,
    kycFileType: KycFileType
  ): Promise<IKycFile> {
    return await this.adminBffService
      .get<IKycFile>({
        url: OrderUrl.kycFile(companyId, orderId, kycFileId),
        params: {
          kycFileType,
        },
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  // TODO: Remove this function and merge its functionality into updateOrder()
  /**
   *
   * @param orderData
   * @returns
   */
  public async updateOrderComplexity(orderData: { isComplex: boolean }): Promise<IOrder> {
    return lastValueFrom(
      this.adminBffService
        .patch<IOrder>({
          url: OrderUrl.orderComplexity(this.companyId, this.orderId$.value),
          body: orderData,
        })
        .pipe(map((response) => response.data))
    );
  }
}
