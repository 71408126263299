import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

// Do not use the short import url, because this causes circular dependency warnings
// noinspection ES6PreferShortImport
import { ToolsService } from '../../core/services/utility-services/tools.service';

/**
 * Interface for route params
 */
export interface IRouteParams {
  [key: string]: number | string;
}

/**
 * Basic options for all urls
 */
interface IUrlOptions {
  /** Whether a slash should be added to the beginning of the url */
  leadingSlash?: boolean;
  /** Whether a slash should be added to the end of the url */
  trailingSlash?: boolean;
}

/**
 * Additional options for urls with param placeholders
 */
interface IOptionsForUrlsWithParams<T extends IRouteParams> {
  /** Params that will replace the param placeholder in url */
  params: T;
}

/**
 * All options for urls with param placeholders
 * @extends IUrlOptions
 * @extends IOptionsForUrlsWithParams
 */
interface IUrlOptionsWithParams<T extends IRouteParams> extends IUrlOptions, IOptionsForUrlsWithParams<T> {}

/**
 * Url for routing.
 * It is capable of automatically replacing param placeholders (e.g. `:orderId`) in the url with given values.
 */
export class RouteUrl<T extends IRouteParams = null> {
  /** Segments of the url. Only available if url contains no parameters! */
  public readonly staticUrlSegments: (string | number)[] | null = null;
  /** Last segment of the url. If last segment is a parameter this parameter is not replaced by the value but just returned (e.g. ':userId') */
  public readonly staticUrlLastSegment: string | null = null;
  /** Absolute url as string. Only available if url contains no parameters! */
  public readonly staticUrlAbsolute: string | null = null;
  /** Absolute raw url as string. Variable parameters are not replaced! */
  public readonly staticUrlAbsoluteRaw: string | null = null;

  /** Segments of routing url */
  private readonly urlSegments: (string | number)[] = [];

  /**
   * Constructor
   */
  constructor(urlSegments: string | (string | number)[]) {
    if (Array.isArray(urlSegments)) {
      this.urlSegments = urlSegments;
    } else {
      this.urlSegments = urlSegments.split('/');
    }

    // Set last url segment to be able to use it in routing files
    this.staticUrlLastSegment = `${this.urlSegments[this.urlSegments.length - 1]}`;

    this.staticUrlAbsoluteRaw = '/' + this.urlSegments.join('/');

    // If url contains variable parameters, do not create static versions of url
    if (this.urlSegments.find((item) => (item + '').startsWith(':'))) {
      return;
    }

    // Set static urls for easier usage in kerberos-compliance/templates
    this.staticUrlSegments = this.urlSegments;
    // tslint:disable-next-line:no-any
    this.staticUrlAbsolute = this.getJoinedUrl(...([{ leadingSlash: true, trailingSlash: false }] as any));
  }

  /**
   * Get n elements of url from the right
   * @param segments Number of segments
   * @returns Joined url elements
   */
  public getLastSegment(segments: number = 1): string {
    return ToolsService.takeRight(this.urlSegments, segments).join('/');
  }

  /**
   * Returns complete url, joined to string
   * @param options Options for the url
   * @param options.leadingSlash Whether a slash should be added to url at the beginning
   * @param options.trailingSlash Whether a slash should be added to url at the end
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url as string with replaced parameters
   */
  public getJoinedUrl(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise `options` is optional and includes all values but `params`.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends null | undefined ? [IUrlOptions?] : [IUrlOptionsWithParams<T>]
  ): string {
    // Get options from the arguments array
    const _options: IUrlOptionsWithParams<IRouteParams> | undefined = options[0];

    const defaultOptions: IUrlOptions = { leadingSlash: false, trailingSlash: false };

    // Add default options as fallback. `options` will overwrite the default options if set
    const mergedOptions: IUrlOptionsWithParams<IRouteParams> = { ...defaultOptions, ..._options };

    return (
      (mergedOptions.leadingSlash ? '/' : '') +
      // Parameter has to be an array and must be casted to `any` to avoid typescript errors.
      // tslint:disable-next-line:no-any
      this.getUrlSegments(...([mergedOptions] as any)).join('/') +
      (mergedOptions.trailingSlash ? '/' : '')
    );
  }

  /**
   * Returns url with replaced parameters
   * @param options Options for the url
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url in segments/array
   */
  public getUrlSegments(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise no parameter is expected.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends null | undefined ? [] : [IOptionsForUrlsWithParams<T>]
  ): (string | number)[] {
    // Get params from the arguments array
    const params = options && options[0] && options[0].params;

    return this.urlSegments.map((segment: string | number) => {
      // Transforms to string
      const segmentAsString: string = segment + '';

      // If segment is placeholder for param
      if (segmentAsString.startsWith(':')) {
        // Get param name by removing ':'
        const param = segmentAsString.substring(1);

        // Check if param is included in `params`
        if (!params || params[param] === undefined || params[param] === null) {
          Logger.error(`RouteUrl: Missing param '${param}' in route: ${this.urlSegments.join('/')}`);
          Logger.error(`Given params: ${JSON.stringify(params)}`);
          return segment;
        }

        // Return the value that should replace the placeholder
        return params[param];
      }
      // Return the url segment if no placeholder
      return segment;
    });
  }
}
