import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { AuthService } from '@services';

/**
 * Service that handles the login of the user
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /** Url the user should be redirected to after successful login */
  private redirectUri: string = window.location.href.split('?')[0];

  constructor(private authService: AuthService) {}

  /**
   * Navigates user to SSO login page
   * @param options Optional login options
   * @param options.inAppRedirectUrl In-app url the user should be navigated to after successful login
   */
  public async login(options?: { inAppRedirectUrl?: string }): Promise<void> {
    const queryParams = {
      client_id: 'kyc-admin-bff',
      access_type: 'offline',
      scope: 'openid',
      response_type: 'code',
      redirect_uri: this.redirectUri,
    };

    const queryParamsEncoded = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    window.location.href = `${environment.loginUrl}?${queryParamsEncoded}`;
  }

  /**
   * Exchanges the code from the SSO service with the BFF
   * @param ssoCode Code from the SSO service
   */
  public exchangeToken(ssoCode: string): Observable<void> {
    return this.authService.exchangeToken(ssoCode, this.redirectUri);
  }
}
