import { Platform } from '@angular/cdk/platform';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environment';
import { ADMIN_APP_ICON_HASH } from '@models';
import { IS_MOBILE, KEB_ICON_HASH, KebIconModule, Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from './transloco-root.module';

import { AppComponent } from './app.component';
import { CustomErrorHandler } from './app.error-handler';
import { appRoutes } from './app.routing';
import { GuardsModule } from './core/guards/guards.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { reducers } from './store/reducers';
import { TotalsItemsInterceptor } from './modules/shared/interceptors/totals-items-interceptors';

// Disable logs only in web-apps in production environment
Logger.setProductionStatus(environment.production);

// TODO: (Steffen) Replace `kebIconModule` with `KebIconModule.forRoot()` in the imports after https://github.com/angular/angular/issues/38376 is resolved
const kebIconModule = KebIconModule.forRoot({
  icons: [
    {
      id: 'KebIcon',
      pathToSprite: 'assets/keb-icons/sprite.svg',
      svgHash: KEB_ICON_HASH,
    },
    {
      id: 'AdminAppIcon',
      pathToSprite: 'assets/icons/admin-app-sprite.svg',
      svgHash: ADMIN_APP_ICON_HASH,
    },
  ],
});

export const ROOT_REDUCER = new InjectionToken<any>('Root Reducer', { factory: () => reducers });

@NgModule({
  declarations: [AppComponent],
  // eslint-disable-next-line prettier/prettier
  imports: [
    BrowserAnimationsModule,
    appRoutes,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    appRoutes,
    HttpClientModule,
    TranslocoRootModule,
    GuardsModule,
    kebIconModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (pl: PlatformLocation) => pl.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: IS_MOBILE,
      useFactory: (pm: Platform) => pm.ANDROID || pm.IOS,
      deps: [Platform],
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TotalsItemsInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
