export enum MIME_TYPE {
  PDF = 'application/pdf',
  GIF = 'image/gif',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  CSV = 'text/csv',
  PLAIN_TEXT = 'text/plain',
  MP4 = 'video/mp4',
  XML = 'application/xml',
}
