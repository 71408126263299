import { Injectable } from '@angular/core';

import { ArrayDataManager, DataManagerService } from '@http';
import { IUser } from '@models';
import { UserUrl } from '@serviceUrls';

@Injectable({
  providedIn: 'root',
})
export class InternalUserService {
  /** Data manager for internal users */
  public internalUser: ArrayDataManager<IUser, undefined>;

  constructor(private dataManagerService: DataManagerService) {
    this.internalUser = dataManagerService.createArrayDataManager({
      update: {
        updateFn: () => UserUrl.getAllInternalUsers(),
      },
    });
  }
}
