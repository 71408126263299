import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgModule } from '@angular/core';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { TranslocoStatusService } from '@utilityServices';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private translocoStatusService: TranslocoStatusService
  ) {}

  /**
   * Loads translation file, depending on given language code
   * @param lang Language code or file name
   */
  public getTranslation(lang: string): Observable<Translation> {
    this.translocoStatusService.setStatus(true, lang);
    return this.http.get<Translation>(
      `${this.baseHref}${this.baseHref.endsWith('/') ? '' : '/'}assets/i18n/${lang}.json`
    );
  }
}

@NgModule({
  imports: [TranslocoMessageFormatModule.forRoot()],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['de'],
        defaultLang: 'de',
        fallbackLang: ['de'],
        reRenderOnLangChange: true,
        prodMode: environment.production,
        flatten: {
          aot: environment.flattenTranslations,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
