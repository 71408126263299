import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for user requests
 */
export class UserUrl extends BasicUrl {
  /**
   * User get me request url
   */
  public static getMe(): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['user', 'me']);
  }

  /**
   * Get all internal/admin users
   */
  public static getAllInternalUsers(): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['user', 'internal']);
  }

  /**
   * Get single user
   */
  public static getSingleUser(companyId: string, userId: string): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['company', companyId, 'user', userId]);
  }
}
