import { IActorAddress } from './actor-address.model';
import { ISOCountryCode } from './country.model';

/**
 * Details of a person that acts as an actor in an order
 */
export interface IIndividualActorDetails {
  /** First name of person */
  firstName: string;
  /** Last name of person */
  lastName: string;
  /** Date of birth in format `yyyy-mm-dd` */
  dateOfBirth: string;
  /** Place of birth */
  placeOfBirth: string;
  /** Nationalities of the person */
  nationalities: ISOCountryCode[];
  /**
   * Optional address of the individual actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship)
   */
  address?: IActorAddress;
  /** Whether the person is the actual contract partner */
  isActualContractPartner: boolean;
  /** Description text to specify the actual contract partner */
  isActualContractPartnerDescription: string;
  /** Whether the person is acting on own economical interest */
  isActingOnOwnEconomicalInterest: boolean;
  /** Description text to specify the economical interest */
  isActingOnOwnEconomicalInterestDescription: string;
  /** Whether the person is not a politically exposed person */
  isNotPep: boolean;
  /** Description text to specify the politically exposed person */
  isNotPepDescription: string;
}

/**
 * Details of a person that acts as an actor in an order
 */
export class IndividualActorDetails implements IIndividualActorDetails {
  /** First name of person */
  public firstName: string;
  /** Last name of person */
  public lastName: string;
  /** Date of birth in format `yyyy-mm-dd` */
  public dateOfBirth: string;
  /** Place of birth */
  public placeOfBirth: string;
  /** Nationalities of the person */
  public nationalities: ISOCountryCode[];
  /**
   * Optional address of the individual actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship)
   */
  public address?: IActorAddress;
  /** Whether the person is the actual contract partner */
  public isActualContractPartner: boolean = true;
  /** Description text to specify the actual contract partner */
  public isActualContractPartnerDescription: string;
  /** Whether the person is acting on own economical interest */
  public isActingOnOwnEconomicalInterest: boolean = true;
  /** Description text to specify the economical interest */
  public isActingOnOwnEconomicalInterestDescription: string;
  /** Whether the person is not a politically exposed person */
  public isNotPep: boolean = true;
  /** Description text to specify the politically exposed person */
  public isNotPepDescription: string;
}
