import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

import { ORDER_URLS } from '@navUrls';
import { OrderService } from '@services/order.service';

/**
 * Triggers loading of order data when navigated to a protected route
 */
@Injectable()
export class OrderGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(private router: Router, private orderService: OrderService) {}

  /**
   * Extracts the order id of the url and set it as currentOrderId for the order service to trigger loading of data
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const orderId: string = route.paramMap.get('orderId');
    const companyId: string = route.queryParamMap.get('companyId');

    if (!orderId || !companyId) {
      this.navigateToOrderList();
      return false;
    }

    this.orderService.updateCurrentIds(orderId, companyId);

    return true;
  }

  /**
   * Triggered when an attempt is made to leave the route.
   * Used to reset the current order id of the order service.
   * Param description is dropped here, because they are unused and only needed for the correct implementation of the CanDeactivate interface.
   */
  public canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean {
    this.orderService.currentOrder.resetService();
    // Leaving the order details is always allowed
    return true;
  }

  /**
   * Navigates to the order list page
   */
  private navigateToOrderList(): void {
    this.orderService.currentOrder.resetService();
    this.router.navigate(ORDER_URLS.list.getUrlSegments()).then();
  }
}
