import { Injectable } from '@angular/core';

import { AdminBffService } from '@http/admin-bff.service';
import { BaseBackendService } from '@http/base-backend.service';
import { UnsubscribeService } from '@kerberos-compliance/kerberos-fe-lib';
import { ArrayDataManager, IArrayDataManagerOptions } from './array.dataManager';
import { DetailsDataManager, IDetailsDataManagerOptions } from './details.dataManager';

/**
 * Service that helps creating DataManager and DataArrayManager objects
 */
@Injectable({
  providedIn: 'root',
})
export class DataManagerService extends UnsubscribeService {
  constructor(private adminBffService: AdminBffService) {
    super();
  }

  /**
   * Instantiates a new DataManager of the given type with the provided options
   * T stands for the type of data
   * O stands for the returned value types of the observables array
   * @param options Options for the DataManager
   * @param backendService Backend service that should be used for the requests (The adminBffService is used as the default)
   */
  public createDetailsDataManager<T, O extends ArrayLike<unknown>>(
    options: IDetailsDataManagerOptions<T, O>,
    backendService: BaseBackendService = this.adminBffService
  ): DetailsDataManager<T, O> {
    return new DetailsDataManager<T, O>({ destroy: this.destroyed, ...options }, backendService);
  }

  /**
   * Instantiates a new DataArrayManager of the given type with the provided options
   * T stands for the type of data
   * O stands for the returned value types of the observables array
   * @param options Options for the DataManager
   * @param backendService Backend service that should be used for the requests (The adminBffService is used as the default)
   */
  public createArrayDataManager<T, O extends ArrayLike<unknown>>(
    options: IArrayDataManagerOptions<T, O>,
    backendService: BaseBackendService = this.adminBffService
  ): ArrayDataManager<T, O> {
    return new ArrayDataManager<T, O>({ destroy: this.destroyed, ...options }, backendService);
  }
}
