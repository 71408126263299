import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, map, tap } from 'rxjs/operators';

import { BaseBackendService } from '@http/base-backend.service';
import { IApiResponse, IRequestParams } from '@models';
import { KebUrl } from '@serviceUrls';
import { catchApiError } from '@shared/rxjs-operators';
import { IDataCache } from './dataCache';
import { DummyDataCache } from './dummy.dataCache';

/**
 * Options for instantiating a DataManager
 */
export interface IBaseDataManagerOptions<T, O extends ArrayLike<unknown>> {
  /** Params for main request */
  requestParams?: object;
  /** Track by function determines by what property objects should be compared by. Used for caching */
  trackBy?: (a: T) => string;
  /** Caching technique */
  dataCache?: IDataCache<T>;
  /** A subject that will end all subscriptions. Used for GC */
  destroy?: Subject<void>;
  /** Update contains means to set the service url and change it dynamically depending on observables */
  update: {
    /** Update service url when any observable emits */
    observables?: Observable<unknown>[];
    /**
     * Function that updates the url of the request, called once initially with an empty array,
     * then when observables update with their data as an array.
     * Remember to check whether the data you require is actually set.
     */
    updateFn: (data: O) => KebUrl;
  };
}

/**
 * Base Data Manager implementation. Allows to easily query data or data arrays for static routes
 * T stands for the type of data
 * MO stands for the type of manager options (must always be the "single" (not array) variant of T
 * O stands for the returned value types of the observables array
 */
export class BaseDataManager<T extends MO | MO[], MO, O extends ArrayLike<unknown>> {
  /* ----- Data Logic ----- */
  // Copy of the given options for easier handling
  /** Params for main request */
  protected requestParams: IRequestParams;

  // ----- MAIN REQUEST ----- //

  /** Service url of the main request */
  protected serviceUrl: KebUrl = null;
  /** Main request, will be canceled if a new main request is made */
  protected request: Subscription;
  /** Whether init function was called */
  private initiated: boolean = false;
  /** Whether data has changed but not reloaded from server */
  protected markedForChange: boolean = false;

  /** Behavior subject of main data */
  protected data: BehaviorSubject<IApiResponse<T>> = new BehaviorSubject<IApiResponse<T>>({ data: null });
  /** Data observable, that can be subscribed to */
  public data$: Observable<IApiResponse<T>> = this.data.asObservable().pipe(
    tap(() => {
      /** Init on first subscription */
      if (!this.initiated) {
        this.initMainRequest();
      }

      /**
       * Every time a new subscription happened,
       * it will be checked if data has changed -> reload data if changed
       */
      this.checkForNewData().then();
    })
  );

  /**
   * Base data manager
   * @param options Options for the DataManager
   * @param backendService Backend service that should be used for the requests
   */
  constructor(protected options: IBaseDataManagerOptions<MO, O>, protected backendService: BaseBackendService) {
    this.initOptionValues(options);
    this.updateDataAfterDependencyChange();
  }

  // ----- GENERAL FUNCTIONS ----- //

  /**
   * Saves the given options in internal variables
   * @param options Options for this service
   */
  protected initOptionValues(options: IBaseDataManagerOptions<MO, O>): void {
    if (options.requestParams) {
      this.setParams(options.requestParams);
    }

    if (!options.dataCache) {
      options.dataCache = new DummyDataCache();
    }

    this.prepareNewRequest();
  }

  /**
   * Subscribes to updateOn observables and calls updateUrl for every new item
   */
  private updateDataAfterDependencyChange(): void {
    // No observables are given, call service url with empty array
    if (!this.options.update.observables || !this.options.update.observables.length) {
      return;
    }

    // subscribe to changes and trigger url change
    combineLatest(this.options.update.observables)
      .pipe(
        // tslint:disable-next-line:no-any | `as any` is needed to prevent a false TS2345 error
        map(this.options.update.updateFn as any),
        catchApiError(() => {
          // tslint:disable-next-line:no-any | `as any` is needed to prevent a false TS2345 error
          this.options.update.updateFn([] as any);
        })
      )
      .subscribe((url) => {
        this.serviceUrl = url as KebUrl;

        if (!this.initiated) {
          return;
        }
        // Reload main data
        this.prepareNewRequest();
        if (this.data.observers.length > 0) {
          // When at least one component subscribed to the data -> reload data immediately
          this.dataHasChanged().catch();
        } else {
          // Otherwise only mark data as changed -> data is only loaded when a component subscribes to it the next time
          this.markDataAsChanged();
        }
      });
  }

  /**
   * Clears all data and sets the service to not initiated
   */
  public resetService(): void {
    this.data.next({ data: null });
    this.initiated = false;
  }

  // ----- MAIN REQUEST ----- //

  /**
   * Initializes the main request
   */
  private initMainRequest(): void {
    this.initiated = true;

    if (!this.serviceUrl) {
      // tslint:disable-next-line:no-any | `as any` is needed to prevent a false TS2345 error
      this.serviceUrl = this.options.update.updateFn([] as any);
    }

    // No Service URL set, so we are probably waiting for dependencies. On a service Url change a new request will be triggered
    if (!this.serviceUrl) {
      return;
    }

    // Initial loading of data
    this.getData()
      .then((data) => {
        this.pushNewData(data);
      })
      .catch((err) => err);
  }

  /**
   * Sets new params for the main request
   * @param params Params
   * @param update Whether data should be reloaded
   */
  public setParams(params: IRequestParams, update: boolean = true): void {
    if (JSON.stringify(this.requestParams) === JSON.stringify(params)) {
      return;
    }

    this.requestParams = params;
    this.prepareNewRequest();
    if (update) {
      this.dataHasChanged().catch();
    }
  }

  /**
   * Returns the current request params of main request
   * @returns Request params
   */
  public getParams(): IRequestParams {
    return this.requestParams;
  }

  /**
   * Creates http params for the main request
   * @returns Http params
   */
  protected createRequestParams(): HttpParams {
    if (!this.requestParams) {
      return undefined;
    }

    let requestParams = {};
    if (this.requestParams) {
      requestParams = this.requestParams;
    }

    const allParams: { [param: string]: string | string[] } = { ...requestParams };
    return new HttpParams({ fromObject: allParams });
  }

  /**
   * Placeholder function that is called whenever a new request will be sent.
   * Might be called multiple times before the first request.
   */
  protected prepareNewRequest(): void {
    // Placeholder function, can be overwritten in derived classes
  }

  /**
   * Loads the main data from server
   * @param debounce Whether multiple requests should be debounced
   * @returns Response as promise
   */
  protected getDataFromServer(debounce: boolean = false): Promise<IApiResponse<T>> {
    return new Promise<IApiResponse<T>>((resolve, reject) => {
      // Cancel ongoing request
      if (this.request) {
        this.request.unsubscribe();
      }

      // Do nothing, if no service url is set
      if (!this.serviceUrl) {
        resolve({ data: null });
        return;
      }

      // Get http params for the main data request
      const params = this.createRequestParams();

      // Make API request
      this.request = this.backendService
        .get<T>({ url: this.serviceUrl, params })
        .pipe(
          debounceTime(debounce ? 500 : 0),
          catchError((err) => {
            this.request = null;
            this.data.next(err);
            reject(err);
            return throwError([err]);
          })
        )
        .subscribe(
          (response: IApiResponse<T>) => {
            this.request = null;
            resolve(response);
          },
          (err) => {
            this.request = null;
            reject(err);
          }
        );
    });
  }

  /**
   * Gets data,
   * can be overwritten to provide cached data
   */
  protected getData(debounce: boolean = false): Promise<IApiResponse<T>> {
    return this.getDataFromServer(debounce);
  }

  /**
   * Pushes new main data. Made protected to be able to modify the data before publishing
   * @param response Response from server
   */
  protected pushNewData(response: IApiResponse<T>): void {
    if (!response || !response.data) {
      this.data.next({ data: null });
    } else {
      this.data.next(response);
    }
  }

  /**
   * Triggers immediate retrieval of data, gets cached results by default
   * @returns Resolves when request was made
   */
  public async dataHasChanged(): Promise<IApiResponse<T>> {
    this.data.next({ data: null });
    this.prepareNewRequest();

    try {
      const data = await this.getData(true);
      this.pushNewData(data);
      return data;
    } catch (error) {
      return Promise.resolve({ data: null });
    }
  }

  /**
   * Loads and returns the data once without updating the data observable
   */
  public async getDataOnce(): Promise<IApiResponse<T>> {
    this.prepareNewRequest();

    try {
      return await this.getData(true);
    } catch (error) {
      return Promise.resolve({ data: null });
    }
  }

  /**
   * Mark data as not up-to-date. Prevents too many requests.
   * Reloads all data when next subscription happens
   */
  public markDataAsChanged(): void {
    this.markedForChange = true;
    this.data.next({ data: null });
    this.prepareNewRequest();
  }

  /**
   * Checks if new data is available and triggers retrieval
   */
  private checkForNewData(): Promise<boolean> {
    if (this.markedForChange) {
      this.markedForChange = false;
      return this.dataHasChanged()
        .then(() => true)
        .catch(() => false);
    }
    return Promise.resolve(true);
  }
}
