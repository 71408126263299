import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginationActions } from '../store/actions';
import { AppState } from '../store/reducers';

@Injectable()
export class TotalsItemsInterceptor implements HttpInterceptor {
  // eslint-disable-next-line ngrx/no-typed-global-store
  public constructor(readonly store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && 'meta' in event.body) {
            this.store.dispatch(PaginationActions.putTotal({ totalItems: event.body.meta.totalItems }));
          }
          return event.clone({ body: event.body });
        }
        return null;
      })
    );
  }
}
