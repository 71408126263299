import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ISOCountryCode } from '@models';
import { ToolsService } from '@utilityServices/tools.service';
import { TranslocoStatusService } from '@utilityServices/transloco-status.service';

/** Country code and translated label for e.g. dropdowns */
export interface ICountry {
  /** ISO code of the country */
  code: ISOCountryCode;
  /** Translated country name */
  label: string;
}

/**
 * This service provides a list of all countries with ISO code and translated name
 */
@Injectable({
  providedIn: 'root',
})
export class CountryService {
  /** List of all available countries */
  private countries: BehaviorSubject<ICountry[]> = new BehaviorSubject<ICountry[]>([]);
  /** List of all available countries. This list is automatically updated if the language is changed. The countries are sorted by label */
  public countries$: Observable<ICountry[]> = this.countries.asObservable();

  constructor(private translocoService: TranslocoService, private translocoStatusService: TranslocoStatusService) {
    combineLatest([translocoStatusService.status$, translocoService.langChanges$])
      // Translate only after successful loading of translations
      .pipe(filter(([status]) => !status.isLoading && status.language === this.translocoService.getActiveLang()))
      .subscribe(() => {
        this.countries.next(
          Object.keys(ISOCountryCode)
            .map((countryCode) => ({
              code: countryCode as ISOCountryCode,
              label: this.translocoService.translate(`general.countries.${countryCode}`),
            }))
            .sort(ToolsService.sortByProp('label'))
        );
      });
  }
}
