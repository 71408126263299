import { RouteUrl } from './route.url';

/** Base order url */
const orderUrl = ['order'];
const orderDetailsUrl = [...orderUrl, ':orderId'];

/** Params for order urls */
type ORDER_PARAMS = {
  orderId: string;
};

/**
 * All modules urls
 */
export const ORDER_URLS = {
  list: new RouteUrl(orderUrl),
  details: new RouteUrl<ORDER_PARAMS>(orderDetailsUrl),
};
