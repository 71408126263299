import { ActorType, ICheck, IOrder, OrderType } from '@models';

/**
 * Service that bundles useful functions
 */
export class ToolsService {
  /**
   * The max file size, for now, is 10MB.
   */
  public static readonly MAX_UPLOAD_FILE_SIZE: number = 1024 * 1024 * 10;

  /**
   * Returns the n last elements from the given array
   * @param arr Array
   * @param n Number of items that should returned
   */
  public static takeRight<T>(arr: T[], n: number = 1): T[] {
    const length = Array.isArray(arr) ? arr.length : 0;

    if (!length) {
      return [];
    }

    n = length - n;
    return arr.slice(n < 0 ? 0 : n);
  }

  /**
   * Returns the first matching check of the given order file
   * @param order Order, containing the checks
   */
  public static getCheck(order: IOrder): ICheck | null {
    const orderType = order?.type;
    const checks = order?.checks || [];

    if (!orderType) {
      return null;
    }

    switch (orderType) {
      case OrderType.INDIVIDUAL:
        return checks.find((check) => check?.actor?.type === ActorType.INDIVIDUAL) || null;
      case OrderType.CORPORATE:
        return checks.find((check) => check?.actor?.type === ActorType.CORPORATE) || null;
      default:
        return null;
    }
  }

  /**
   * Sorts an array of objects by the given property name
   * @param prop Name of the property to sort by
   */
  // tslint:disable-next-line:no-any
  public static sortByProp(prop: string): (a: { [key: string]: any }, b: { [key: string]: any }) => number {
    return (a, b) => {
      if (typeof a[prop] === 'number' && typeof b[prop] === 'number') {
        return (a[prop] as number) - (b[prop] as number);
      }

      return (a[prop] as string)?.localeCompare(b[prop] as string);
    };
  }

  /**
   * Returns a human readable file size of the given bytes
   * @param bytes Number of bytes of the file
   * @param decimals Number of decimals
   * Source:
   * @link https://stackoverflow.com/a/18650828/13195015
   */
  public static formatBytes(bytes: number, decimals: number = 1): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Converts a given file to a base64 string
   * @param file File that should be converted
   */
  public static async fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  /**
   * Flattens an array of arrays to a flattened array
   * @param array Array of arrays
   */
  public static flattenArray<T>(array: T[][]): T[] {
    return ([] as T[]).concat.apply([], array);
  }

  public static validFileSize(file: File): boolean {
    return file.size <= ToolsService.MAX_UPLOAD_FILE_SIZE;
  }
}
