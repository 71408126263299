import { ActorAddress, IActorAddress } from './actor-address.model';
import { IActorDocument } from './actor-document.model';
import { IActor } from './actor.model';
import { MetaModel } from './meta.model';

/**
 * Check that stands for the relationship between an actor and an order
 */
export interface ICheck {
  /** Id of the check */
  checkId: string;
  /** Actor that is related to the order */
  actor?: IActor;
  /** List of linked documents */
  documents?: IActorDocument[];
  /** Address of the actor */
  actorAddress?: IActorAddress;
}

/**
 * Check that stands for the relationship between an actor and an order
 */
export class Check extends MetaModel implements ICheck {
  /** Id of the check */
  public readonly checkId: string;
  /** List of linked documents */
  public documents: IActorDocument[] = [];
  /** Address of the actor */
  public actorAddress: IActorAddress = new ActorAddress();

  /**
   * Check that stands for the relationship between an actor and an order
   * @param actor Actor that should be linked with the order
   */
  constructor(public actor: IActor) {
    super();
  }
}
