import { createAction, props } from '@ngrx/store';
import { Pagination } from '../../models/pagination.models';

export const initPagination = createAction('[PAGINATION] initPagination');

export const putPagination = createAction('[PAGINATION] putPagination', props<{ pagination: Pagination }>());

export const putPageSize = createAction('[PAGINATION] putPageSize', props<{ pageSize: number }>());

export const putTotal = createAction('[PAGINATION] putTotal', props<{ totalItems: number }>());
