import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PUBLIC_URLS } from '@navUrls';
import { UserService } from '@services';

/**
 * This interceptor checks every incoming request response and navigates to the authentication page if user is not authorized
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private userService: UserService) {}

  /**
   * Navigates to authentication page if user is unauthorized
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.userService.invalidateUser();
          this.router.navigate(PUBLIC_URLS.authentication.getUrlSegments()).then();
        }
        return throwError(error);
      })
    );
  }
}
