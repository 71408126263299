import { IApiResponse } from '@models';
import { IDataCache } from './dataCache';

/**
 * Dummy data cache always reject get requests,
 * this makes the data manager send a real request.
 * All save requests resolve so the manager continues without an error
 */
export class DummyDataCache<T> implements IDataCache<T> {
  /**
   * Returns an item from the cache
   * @param identifier identifier to get items by, determined by trackBy
   */
  public getItem(identifier: string): Promise<T> {
    return Promise.reject();
  }

  /**
   * Saves the given item to the cache
   * @param item item to save
   */
  public setItem(item: T): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Saves a list of items for the given identifier, usually the url
   * @param identifier identifier to save list by
   * @param data items to save
   */
  public setList(identifier: string, data: IApiResponse<T[]>): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Returns a list of items for the given URL from Cache
   */
  public getList(): Promise<IApiResponse<T[]>> {
    return Promise.reject();
  }

  /**
   * Clears all data from cache
   */
  public clearData(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Clears all list data, but leaves item data in place
   */
  public clearLists(): Promise<void> {
    return Promise.resolve();
  }
}
