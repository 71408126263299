import { IFile } from './file.model';
import { IMetaModel, MetaModel } from './meta.model';

/**
 * An ActorDocument can be a scan of an ID card, certificate or other verification document scanned/photographed by the user
 */
export interface IActorDocument extends IMetaModel {
  /** Id of the document */
  documentId?: string;
  /** Type of the document */
  type: ActorDocumentType;
  /** List of files that belong to this document. For example, this can be the front and back of an ID card */
  files: IFile[];
}

/**
 * Available document types
 */
export enum ActorDocumentType {
  CORPORATE_AUTHORITY_DOCUMENT = 'CORPORATE_AUTHORITY_DOCUMENT',
  CORPORATE_COMMERCIAL_REGISTER_DOCUMENT = 'CORPORATE_COMMERCIAL_REGISTER_DOCUMENT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  INDIVIDUAL_ID_CARD = 'INDIVIDUAL_ID_CARD',
  INDIVIDUAL_PASSPORT = 'INDIVIDUAL_PASSPORT',
  RESIDENCE_TITLE = 'RESIDENCE_TITLE',
  SIGNATURE = 'SIGNATURE',
}

/**
 * An ActorDocument can be a scan of an ID card, certificate or other verification document scanned/photographed by the user
 */
export class ActorDocument extends MetaModel implements IActorDocument {
  /** Id of the document */
  public readonly documentId?: string;
  /** List of files that belong to this document. For example, this can be the front and back of an ID card */
  public files: IFile[] = [];

  /**
   * An ActorDocument can be a scan of an ID card, certificate or other verification document scanned/photographed by the user
   * @param type Type of the document
   */
  constructor(public readonly type: ActorDocumentType) {
    super();
  }
}
