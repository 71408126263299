
import { createAction, ActionReducer } from '@ngrx/store';

export const clearState = createAction('[LogoutMetaReducer Action] clearState');

export function logoutMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === clearState.type) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
