/**
 * Types of LocalStorageKeys
 */
export enum LocalStorageKeyTypes {
  BOOLEAN = '__eb',
  NUMBER = '__en',
  STRING = '__es',
  JSON = '__ej',
}

/**
 * Enum with valid keys for local storage.
 * The suffix (__xx) defines its type. See `LocalStorageKeyTypes` above for available types
 */
export enum LocalStorageKeys {
  LAST_LOGIN_ATTEMPT = `lla__es`,
}
