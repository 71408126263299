import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
import { OrderGuard } from './order.guard';

/**
 * List of guards
 */
const KEB_GUARDS = [AuthGuard, OrderGuard];

/**
 * Guards module
 */
@NgModule({
  imports: [CommonModule],
  providers: [...KEB_GUARDS],
})
export class GuardsModule {}
